@import url('https://fonts.googleapis.com/css2?family=Cutive&family=Montserrat+Subrayada:wght@700&family=Noto+Serif+Devanagari:wght@500&family=Pacifico&family=Sriracha&family=Staatliches&family=Trocchi&display=swap');

.header-gap{
    width: 100%;
    height: calc(5.5vh + 1px + 6.8vh);
    background-color: #000;
}
.secondary-pages-header{
    position: relative;
    height: 55vh;
    width: 100%;
    
    background-position: bottom;
    
    background-repeat: no-repeat;
    box-shadow: inset 0 -0.5rem 1rem black;
    overflow: hidden;
}
.secondary-pages-header img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.outer-text-wrapper h1 {
    margin: 0 auto;
    text-align: center;
    font-family: 'Pacifico', cursive;
}

.outer-text-wrapper h2 {
    margin: 0 auto;
    text-align: center;
    font-family: 'Montserrat Subrayada', sans-serif;
}

.page-title-container{
    width: 100%;
    height: 10rem;
    position: absolute;
    bottom: 0;
    background: rgba(0,0,0,0.8)
}

.page-title-container div{
    display: grid;
    align-items: center;
    height: 100%;
}

.page-title-container h2{
    font-size: clamp(1.4rem, 2vw, 2vw);
    color: rgb(240,240,240);
    text-align: center;
    margin: 0;
}

.outer-text-wrapper{
    
    width: 100%;
    margin: 0 auto; 
    background: rgba(230,230,230,0.5);
    backdrop-filter: sepia(80%) blur(2px) contrast(120%);
    -webkit-backdrop-filter:sepia(80%) blur(2px) contrast(120%);
}

.outer-text-wrapper h1{
    font-size: clamp(1.7rem, 2.3vw, 4rem);
    color: hsl(0, 83%, 32%);
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;  
}
.outer-text-wrapper h2{
    font-size: clamp(1.3rem, 1.8vw, 3rem);
}



.fkr{
    transition: transform 1s;
    z-index: -1;
    display: grid;
    grid-template-columns: repeat(5, 100%);
    position: absolute;
    transform: translateX(100%);
    height: 150%;
    top: 0;
}


@media(min-width: 1900px){
    .fkr{
        height: 250%
    }
}

@media(max-width: 700px){
    .header-gap{
        height: calc(3rem + 1px + 5.5vh)
    }
}
