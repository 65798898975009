.Gallery{
    position: relative;
    overflow: hidden;
}
.gallery-background{
    background: url('../../assets/444.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    inset: 0;
    z-index: -2;
    filter: blur(4px) opacity(15%);
}

.Card{
    width: 90%;
    height: 50vh;
    padding: 0;
    cursor: pointer;
}

@import url('https://fonts.googleapis.com/css2?family=Cutive&family=Montserrat+Subrayada:wght@700&family=Noto+Serif+Devanagari:wght@500&family=Oswald:wght@500&family=Pacifico&family=Sriracha&family=Staatliches&family=Trocchi&display=swap');

.before-and-after-gallery-grid-container{
    display: grid;
    margin: 0 auto;
    justify-items: center;
    align-items: center;
    
    border-radius: 0 0 1rem 1rem;
    width: 79vw;
    grid-template-columns: 50% 50%;
    padding: 2rem 0;
    gap: 2rem 0;
    
}

.front-inner-wrapper, .front-inner-wrapper{
    background-size: cover;
    background-repeat: no-repeat;
   
}
/* .before-and-after-gallery-grid-container > :last-child hr{
    display: none;
} */
.gallery-wrapper{
    /* background: rgba(0, 0, 0, 0.7); */
    background: rgba(255,255,255,0.65);
    margin: 0 auto;
    width: 80%;
    padding: 0vh 5vw 3vh 5vw;
}
.gallery-intro{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6));
    height: 8vh;
    
    width: 100%;
    margin: 0 auto;
    
    text-align: center;
    border-radius: 0 0 2rem 2rem;
    overflow: hidden;
}

.sectionButton{
    font-family: 'Oswald', sans-serif;
    padding-top: 1rem;
    cursor:pointer;
    background: hsl(95, 47%, 27%);
    /* background: rgba(0, 0, 0, 0.75); */
    /* color: rgb(220, 220, 220); */
    color: rgb(10,10,10);
    margin: 0;
    font-size: clamp(1rem, 1.7vw, 2vw);
}

.gallery-intro h2:hover{
    /* color: rgb(10,10,10); */
    /* background: rgb(220, 220, 220); */
    /* background: rgba(0, 0, 0, 0.75); 2nd EDIT*/
    background-color: hsl(95, 35%, 20%);

    color: rgb(220, 220, 220); 
    

}
@media(max-width: 1600px){
    .Card{
        height: 40vh;
    }
}

@media(max-width: 1200px){
    .before-and-after-gallery-grid-container{
        grid-template-columns: 90%;
        justify-content: center;
    }
}

@media(max-width: 600px){
    .before-and-after-gallery-grid-container{
        grid-template-columns: 100%;
        justify-content: center;
    }
    .Card{
        height: 35vh;
    }
}

@media(max-width: 450px){
    .before-and-after-gallery-grid-container{
        grid-template-columns: 100%;
        justify-content: center;
    }
    .Card{
        height: 25vh;
    }
}




.card-content{
    margin: 0;
    
    height: 100%;
    position: relative;
    text-align: center;
    position: relative;
    transition: all 1.8s cubic-bezier(0.52,1,0.37,1);
    
    
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    
    
}


/* CHANGE WIDTH AND TRANSLATE Z HERE TO ADJUST THE WIDTH OF THE CARD, ALSO ON 
THE ::AFTER (ALTHOUGHT THIS CAN MAYBE BE DELETED ALLTOGETHER, LOOK BELOW WHAT ELSE TO CHANGE FOLLOWING THAT, IF THE BEZIER CURVE'S LAST INPUT IS <= 1)
(TRANSLATE Z TO BE HALF OF THE WIDTH HERE */
.card-content::before{
    background-color: hsl(154, 13%, 11%);
    width:14px;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    transform: rotateY(90deg) translateZ(-7px);
    
}

.card-content::after{
    background-color: hsl(154, 13%, 11%);
    width:14px;
    height: 100%;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    transform: rotateY(90deg) translateZ(7px);
}

.front, .back{
    
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: 50% 50% !important;
   

    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    border-radius: 0.1rem;
    border: 1px solid hsl(0, 0%, 18%);
    
    
}

.back-outer-wrapper, .front-outer-wrapper{
    border: 5px solid hsl(154, 13%, 11%);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

/* background: hsla(0, 0%, 88%, 0.9); */
.front-title{
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;
    
    background: hsla(0, 0%, 88%, 0.8);
   text-shadow: 1px 1px 10px rgb(20,20,20);
    
    transform:  translateZ(100px);
    -webkit-transform:  translateZ(100px);

    transition: all 0.25s;
}

.Card:hover .front-title{
    opacity: 1;
}

.back-title{
    position: absolute;
    bottom: 0;
    width: 100%;
    background: hsla(0, 0%, 88%, 0.8);
   text-shadow: 1px 1px 10px black;
   
   transform: translateY(30rem) translateZ(100px);
   -webkit-transform:  translateZ(100px);
 }

/* KEEP TRANSLATE Z HERE TO HALF OF THE ::BEFORE/AFTER WIDTH */
.front-outer-wrapper{
    transform: translateZ(7px);
   -webkit-transform: translateZ(7px);
}
/* KEEP TRANSLATE Z HERE TO HALF OF THE ::BEFORE/AFTER WIDTH */
.back-outer-wrapper{
    transform: translateZ(-7px);
   -webkit-transform: translateZ(-7px);
}

.back{
    background-size: contain;
    transform: rotateY(180deg);
   -webkit-transform: rotateY(180deg) 
}

.front{
    background-size: contain;
}

/* .Card:focus .card-content{
    transform: rotateY(-180deg)
    
    
} */


.preview-cover{
    display: grid;
    align-items: center;
    background: #000;
    position: absolute;
    width: 100%;
    height: 100%;

    opacity: 0.95;
    transition: all 0.25s;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    color: hsl(95, 47%, 27%);
}

.Card:hover .preview-cover{
    opacity: 0;
}

.preview-cover h2{
    transform:  translateZ(100px);
    -webkit-transform:  translateZ(100px);
}
.Card hr{
    margin-top: 1rem;
}

.general-gallery-grid-container{
    width:95%;
    margin: 0 auto;
    padding: 2rem 0;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    

    justify-content: center;
    align-items: center;
    gap: 12px 12px;
    
}
.general-gallery-image-wrapper{
    position: relative;
    
    width: 100%;
    height: 20rem;
    
    border-radius: 0.2rem;
    border: 1px rgb(32, 32, 32) solid;
    box-shadow: 0 0 0.15rem 0.05rem rgb(49, 49, 49);

    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 0.2s
}

.general-gallery-image-wrapper img{
    /* background: rgb(18, 18, 18); */
    width: inherit;
    height: inherit;
    object-fit: cover;
    
}

.general-gallery-image-wrapper:hover{
    box-shadow: 0 0 0.3rem 0.1rem black;
}
.gallery-image-opened{
    display: grid;
    justify-items: center;
    align-items: center;
    position: fixed;
    background: rgb(0,0,0,1);
    height: 100%;
    width: 100%;
    inset: 0;
    margin: 0 auto;
    z-index: 3000;
    overflow: hidden;
}

.gallery-image-opened img{
    width: 95vw;
    height: 90vh;
    object-fit: contain;

}

@media(max-width: 900px){
    .general-gallery-grid-container{
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width: 550px){
    .general-gallery-grid-container{
        grid-template-columns: 1fr;
    }
}

.on-hover-info{
    position: absolute;
    width: 100%;
    height: 30%;
    background-color: rgba(213, 213, 213, 0.6);
    opacity: 0;
    bottom: 0;  
    transition: opacity .6s, box-shadow 0.3s, background-color 0.24s ;
    box-shadow: inset 0rem 0rem 3rem 2rem rgba(213, 213, 213, 1),  0rem 0rem 1rem 2rem rgba(213, 213, 213, 1);

    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.general-gallery-image-wrapper:hover .on-hover-info{
    opacity: 1;
    box-shadow: none;
    background-color: rgba(21, 21, 21, 0.9)
}

.opened-img-msg{
    margin: 5px 0;
    color: rgb(240, 240, 240);
    text-shadow: unset;
    animation-name: colorBlink;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    
}

@keyframes colorBlink{
    0%{
        color: rgb(201, 201, 201);
        text-shadow: unset;
    }

    12.5%{
        color: #000;
        text-shadow: 0 0 1rem #000
    }

    25%{
        color: rgb(201, 201, 201);
        text-shadow: unset;
    }

    37.5%{
        color: #000;
        text-shadow: 0 0 1rem #000;
    }

    50%{
        color: rgb(201, 201, 201);
        text-shadow: unset;
    }

    62.5%{
        color: #000;
        text-shadow: 0 0 1rem #000;
    }

    75%{
        color: rgb(201, 201, 201);
        text-shadow: unset;
    }

    87.5%{
        color: #000;
        text-shadow: 0 0 1rem #000;
    }

    100%{
        color: rgb(201, 201, 201);
        text-shadow: unset;
    }

    
}