@import url('https://fonts.googleapis.com/css2?family=Cutive&family=Montserrat+Subrayada:wght@700&family=Noto+Serif+Devanagari:wght@500&family=Pacifico&family=Sriracha&family=Staatliches&family=Trocchi&display=swap');

html{
  padding: 0;
  margin: 0;
  overflow: unset;
  height: 100%;
  /* scroll-behavior: smooth; */
  
}

body{
  
  height: 100%
}
