@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.Header {
    
    background: url("../assets/sky-1141930_1920.jpg");
    height: 90vh;
    background-color: rgba(0,0,0,0.99);
    background-size: cover;
    
    
    position: relative;
    
    
    

}

/* .header-nav{
    position: fixed;
    width: 100vw;
    z-index: 10;
    transition: all 0.3s;
} */
/* background: linear-gradient(45deg, hsl(73, 60%, 30%) 0 30%, hsl(113, 55%, 25%) 70% 100%); */
/* .contactAndSocialMediaNav{
    height: 2.2rem;
    background: hsl(95, 47%, 27%);
    
    display: grid;
    grid-template-columns: 30% 70%;
} */

/* .socials{
    display: grid;
    grid-template-columns: 1fr 1fr;
} */

/* .main-nav{
    height: 5rem;
    background-color: hsla(0, 0%, 0%, 0.8);
    animation-name: appear;
    animation-duration: 1s;
    animation-direction: normal;
} */

.separation-line{
    height: 1px;
    background-color: white;
}

/* .contactTitleAndLink{
    margin: 0;
    justify-self: center;
} */

/* .contactTitleAndLink1{
    margin: 0;
    justify-self: end;
} */


.image-mask{
    position: absolute;
    z-index: 2;
    
    inset: 0 0;
    background: linear-gradient(
                                rgba(100, 93, 0, 0.5) ,
                                rgba(100, 93, 0, 0.6),
                                rgba(100, 93, 0, 0.7),
                                rgba(100, 93, 0, 0.8),
                                rgba(100, 93, 0, 0.9),
                                rgba(100, 93, 0, 0.91),
                                rgba(100, 93, 0, 0.95),
                                rgba(100, 93, 0, 0.99),
                                rgba(100, 93, 0, 0.99),
                                rgba(100, 93, 0, 0.99),
                                rgba(100, 93, 0, 0.99),
                                rgba(100, 93, 0, 0.99),
                                rgba(29, 70, 17, 0.995) ,
                                rgba(29, 70, 17, 0.995) ,
                                rgba(29, 80, 14, 1) 
                                );
    -webkit-mask: url("../assets/grassMask.png");
    mask: url("../assets/grassMask.png");
    -webkit-mask-position: bottom ;
    mask-position: bottom ;
    -webkit-mask-size: 70%;
    mask-size: 70%;
    
    -webkit-mask-repeat:repeat-x;
    mask-repeat:repeat-x;
    
}

.image-mask2{
    
    
    inset:0 ;
    background: linear-gradient(
                                rgba(180,0,0, 0.5),
                                rgba(180,0,0, 1)
                                );
    -webkit-mask: url("../assets/floral-1751088.svg");
    mask: url("../assets/floral-1751088.svg");
    -webkit-mask-position: top;
    mask-position: top;
    -webkit-mask-size: clamp(18rem, 45%, 45rem);
    mask-size: clamp(18rem, 45%, 45rem);
    height: 50vh;
    
    -webkit-mask-repeat: no-repeat !important;
    /* mask-repeat: space !important; */
    
}


.h1-outside-mask{
    transition: all 0.2s;
    width: 100%;
    text-align: center;
    color: rgb(181, 28, 28);
    text-shadow: -1px -1px 1rem black, 1px -1px 0.2rem black, -1px 1px 0.2rem black, 1px 1px 0.2rem black;
    align-self: end;
    
    
    
    font-family: 'Pacifico', cursive;
    font-size: clamp(1.5rem, 3.5vw, 5rem);
}



.sun-img {
    width: clamp(10rem, 25%, 20rem);;
    z-index: -1;
    position: fixed;
    top: 16.5rem;
    left: 50%;
    transform: translate(40%, -80%);
    transition: all 0.2s;
    border-radius: 50%;
    animation-name: sunrise;
    animation-duration: 3.5s;
    
    
}


/* .idk{
    margin: 0;
    padding: 1rem 3rem ;
    z-index: 15;
    color: white;
    display: grid;
    justify-content: center;
    text-align: center;
    list-style: none;
    grid-template-columns: 40% 10% 10% 10% 10% 10% 10%;
    gap : 5px;
    text-decoration: none;
} */

/* .home-btn{
    
    width: 40%;
    height: 3rem
} */



@keyframes appear {
    0% {
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.top-gap{
    height: 1rem;
    width: 100%;
    background-color: black;
}

.container-mask{
    position: absolute;
    
    inset: 0 0;
    background: hsla(0, 0%, 0%, 0.0);
    -webkit-mask: url("./../assets/autumn-3418440_960_720.png");
    mask: url("./../assets/autumn-3418440_960_720.png");
    -webkit-mask-size: 200%;
    mask-size: 200%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;

}

.containerhh{
    top: 10rem;
    position: fixed;
    width: 100%;
    display: grid;
    
    
    
    grid-template-rows: 30% 70%;
    opacity: 0;
    animation-name: appear;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

@keyframes appear{
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1
    }
}


@keyframes sunrise {
    0%{
        transform: translate(40%, 60%);
        opacity: 0;
        
    }

    100%{
        transform: translate(40%, -80%);
        opacity: 1;
       
    }
}

.lulimg{
    width: 50%;
}


#img1{
    z-index: 100;
    position: absolute;
    bottom: 0;
    width: 40%;
    opacity: 0;
    background-repeat: repeat-x;
}
/* grass */
/* #img2{
    width: 100%;
    height: 100%;
    background: url('../assets/grassMask.png');
    bottom: 0;
    position: absolute;
    background-size: contain;
    background-repeat: repeat-x;
    background-position: bottom;
    z-index: 1000;

} */
/*purple tullips*/
#img3{
    transition: all 0.2s;
    filter: saturate(120%) hue-rotate(50deg) brightness(95%) grayscale(20%);
    width: 150%;
    
    height: 80%;
    background: url('../assets/purpleTulips.png');
    bottom: 0;
    position: absolute;
    background-size: contain;
    background-repeat: repeat-x;
    background-position: bottom;
    z-index: 1003;
    
    
}
/* orange sponge flowers at back*/
/* #img4{
    filter: saturate(120%) hue-rotate(340deg) brightness(95%) grayscale(20%);
    width: calc(100% + 50px);
    height: 100%;
    background: url('https://cdn.pixabay.com/photo/2018/01/18/01/53/nature-3089398_960_720.png');
    bottom: 0;
    position: absolute;
    background-size: contain;
    background-repeat: repeat-x;
    background-position: bottom;
    z-index: 1002;
    translate: -50px;

} */
/* white roses at back*/


/* #img5{
    transition: all 0.2s;
    filter: saturate(100%)  brightness(150%) contrast(90%) ;
    width: 150vw;
    height: 140vh;
    background: url('../assets/lower-clouds.png');
    
    left: 0;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 -10vh;

    z-index: 0;

    
} */

#img5{
    width: 200%
}


/*left branch*/
#img6{
    width: calc(100%);
    
    height: 90% ;
    background: url('../assets/rose-bush.png');
    top: 2rem;
    left: 10%;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    z-index: 2003;
    transition: all 0.2s;
    transform:  rotateX(180deg)
    
}

#img10{
    transition: all 0.2s;
    width: calc(100%);
    
    height: 90% ;
    background: url('../assets/rose-bush.png');
    top: 2rem;
    right: 10%;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    z-index: 2003;
    transform: rotateY(180deg) rotateX(180deg)
}

/*yellow tullips at front*/
#img9{
    transition: all 0.1s;
    filter: saturate(150%) hue-rotate(320deg) brightness(85%) grayscale(10%);
    width: 150%;
    
    height: 80%;
    background: url('../assets/foreground-flowers.png');
    bottom: 0;
    
    position: absolute;
    background-size: contain;
    background-repeat: repeat-x;
    background-position: bottom;
    z-index: 1003;   
}





.flowers-container{
    /* filter: saturate(120%) hue-rotate(300deg) brightness(95%) grayscale(20%); */
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 30%;
    z-index: 1003;
}



@media(max-width: 1000px){
    #img10, #img6{
        height: 80%
    }
}

@media(max-width: 750px){
    #img10, #img6{
        height: 65%
    }
}

@media(max-width: 650px){
    #img10, #img6{
        height: 60%
    }
}
@media(max-width: 600px){
    #img10, #img6{
        height: 50%
    }
}

@media(max-width: 450px){
    #img10, #img6{
        height: 38%
    }
}