.services2-text-wrapper{
    position: relative;
    width: 100%;
    overflow: hidden;
}

.services2{
    display: grid;
    align-items: start;
}


.services-section-image {
    border-radius: 0.3rem;
    width: 100%;
    object-fit: cover !important;
}

.services2-hidden-placeholder{
    opacity: 0;
}

.absolute-content-to-appear{
    position: absolute;
    top: 0%;
    height: 100%;
    width: 100%;
    
}

.services2 p{
    font-family: 'Noto Serif Devanagari', serif;
    line-height: 140%;
    letter-spacing: 0.1;
    color: rgb(20,20,20);
    font-size: clamp(0.75rem, 1.6vw, 1.5rem);
}
.services2 p{
    margin: 0 5%;
    
}

.services2:last-of-type {

    
    /* box-shadow: inset 75vw 0rem  1.5rem 0rem black, 
                inset 150vw 0rem  1.5rem 0rem hsla(100, 63%, 10%, 0.8);
    */
    
    animation-name: servicesSlide;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 0; 
}

@keyframes servicesSlide{
    0%{
        top: -100%;
        
    
        
    }
    50%{
        
        
        /* box-shadow: inset 75vw 0rem  1.5rem 0rem black, 
        inset 150vw 0rem  1.5rem 0rem hsla(100, 63%, 10%, 0.8); */


    }
    100%{
        top: 0%;
        /* box-shadow: inset 0 0rem  0 0rem black, 
        inset 0 0rem  0rem 0rem hsla(100, 63%, 10%, 0.8); */
    }
}