.Reviews{
    height: 25vh;
}

.review-change-container{
    height: 10vh;
    display: grid;
    grid-template-columns: 15% 70% 15%;
    text-align: center;
    align-items: center;
}

.review-container{
    display: grid;
    
    align-items: center;
}

.review-container p:nth-child(2){
    font-size: clamp(0.9rem, 1.8vw, 5rem);
}


.review-change-container button{
    font-size: clamp(1.5rem, 3vw, 6rem);
    
    color: hsl(95, 47%, 30%);
    border: none;
    background: none;
    
    margin: 0 auto;
    height: 130%;
    
    cursor: pointer;
}
@import url('https://fonts.googleapis.com/css2?family=Cutive&family=Farsan&family=Montserrat+Subrayada:wght@700&family=Noto+Serif+Devanagari:wght@500&family=Oswald:wght@500&family=PT+Sans+Narrow&family=Pacifico&family=Sriracha&family=Staatliches&family=Trocchi&display=swap');
.review-change-container p:first-of-type{
    font-family: 'Farsan', cursive;
    transform: skewX(-10deg);
    color: hsl(35, 51%, 30%);
    opacity: 0.95;
    font-size: clamp(1rem, 2.1vw, 6rem)
}

.review-change-container button:hover{
    color: hsl(95, 47%, 15%);
}

.see-more-div{
    font-size: clamp(0.8rem, 1vw, 4rem);
}