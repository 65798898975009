
@import url('https://fonts.googleapis.com/css2?family=Cutive&family=Montserrat+Subrayada:wght@700&family=Noto+Serif+Devanagari:wght@500&family=Pacifico&family=Sriracha&family=Staatliches&family=Trocchi&display=swap');






/* background: linear-gradient(90deg,
    hsl(73, 64%, 35%) 25%,
    hsl(113, 60%, 21%) ); */

.Services{
    position: relative;
    overflow: hidden;
}









.individual-services-container{
    overflow: hidden;
    margin: 0 auto;
    padding: 1rem;
    width: 95%;
}




/* style={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.8)', display: 'grid', gridTemplateColumns: 'auto auto'}} */





.grid-container-mask1 {
   
    inset: 0;
    background: linear-gradient(72.5deg, hsl(69, 70%, 45%), hsl(107, 70%, 30%)) ;
    -webkit-mask: unset;
    
    
}

.services-grid-container2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 20rem;
    rotate: 180deg;

}

.services-grid-container2 p {
   
}








 

/*
@keyframes servicesTopSlide{
    0%{
        top: 0
    }
    100%{
        top: -100%;
        display: none;
    }
}
.services3, .services4, .services5{
    
    
    height: 12.1rem;
    animation-name: servicesTopSlide;
    animation-duration: 0.2s;
    
    animation-fill-mode: forwards;
}

.services3{
    backdrop-filter: blur(27px);
    z-index: 50
    
}
.services4{
    backdrop-filter: blur(9px);
    z-index: 49;
    animation-delay: 0.2s;
}
.services5{
    backdrop-filter: blur(3px); 
    z-index: 100;
    animation-delay: .3s;
} */

.whole-services-container{
    display: grid;
    margin: 0 auto;
    padding: 2rem 0;
    grid-template-columns: 0.2fr 0.8fr;
    align-items: center;
    gap: 2rem 1rem;
}



@media(max-width: 700px){
    .whole-services-container {
        grid-template-columns: unset;
        grid-template-rows: auto;
        justify-content: center;
        gap: 1.5rem 1rem;
        align-items: center;
    }
}





/* ServicesPageSection css*/

.ServicesPageSection{
    
    border-radius: 2rem 0rem 0 2rem;
    overflow: hidden;
    margin: 2rem auto 0 0;
    transition: all 1.25s;

    
    box-shadow:  -0.2rem 0 0.2rem 0.08rem hsl(95, 47%, 10%);
    background: linear-gradient(90deg, rgba(240, 240, 240, 0), rgba(180, 180, 180, 1), rgba(240, 240, 240, 1))
}


.services-container{
    height: 15%;
    width: 65vw;
    overflow: hidden;
    
}

.grid-wrapper{
    /* background: rgba(0,0,0,0.6); */
    /* background: rgba(240, 240, 240, 0.8); */
    background: none;
    height:100%;
    width: 65vw;
    overflow: hidden;

    display: grid;
    justify-items: start;
    align-items: center;
    grid-template-columns: 40% 60%;
    
}

.services-grid-item2{
    
    /* background: rgba(240, 240, 240, 0.8); */
    padding: 0;
    height: 100%;
    margin: 0;
    display: grid;
    grid-template-columns: unset
}

.services-grid-item2 img{
    width: 100%;
    border-radius: 0 0 0.25rem 0;
   
}

.services-grid-item2 h2{
    margin: 2.5px 0;
    text-align: center;
    color: #000;
    align-self: unset;
    font-size: clamp(0.8rem, 1.7vw, 5rem);
}
.individual-service-slide-down-cover{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, 1);
    transition: bottom 3s;
    box-shadow: 1rem 1rem 1rem 1.5rem rgba(240, 240, 240, 1);
}

.services-grid-item1{
    width: 100%;
    height: 100%;
    /* background-color: rgba(0,0,0,0.8); */
    /* background: rgba(240, 240, 240, 0.8); */
}
.services-grid-item1 p{
    margin: 5% 5% 10% 3%;
    font-size: clamp(0.8rem, 1.1vw, 5rem)
}

@media(max-width: 730px){
    .ServicesPageSection{
        box-shadow:  0rem -0.2rem 0.2rem 0.08rem hsl(95, 47%, 10%);
        border-radius: 1rem 1rem 0.15rem 0.15rem;
        background: linear-gradient( rgba(240, 240, 240, 0), rgba(180, 180, 180, 1), rgba(240, 240, 240, 1))
    }

    .grid-wrapper{
        display: grid;
        width: 100%;
        grid-template-columns: unset;
        grid-template-rows: 35% 65% ;
    }

    .services-grid-item2{
        /* display: grid;
        grid-template-columns: auto auto;
        grid-row: 1 / -3; */
        display: unset;
        height: 100%;
        position: relative;
        
        overflow: hidden;
    }
    .services-grid-item1{
       position: relative;
    }
    .services-grid-item1 p{
        color: #000;
        line-height: 1.8rem;
        ;
    }
    
    .services-grid-item2 h2 {
        position: absolute;
        border-bottom: 1px black solid;
        border-top: 1px black solid;
        bottom: -2px;
        /* background: #000; */
        background: rgba(240, 240, 240, 0.8);
        left: 0;
        right: 0;
    }
    
    .services-grid-item1{
        grid-row: 2 / -2
        
    }

    .services-container{
        
        width: 100%;
    }
}

@media(max-width: 330px){
    .grid-wrapper{
        display: grid;
        
        grid-template-columns: unset;
        grid-template-rows: 30% 70% ;
    }
}