@import url('https://fonts.googleapis.com/css2?family=Cutive&family=Montserrat+Subrayada:wght@700&family=Noto+Serif+Devanagari:wght@500&family=Pacifico&family=Sriracha&family=Staatliches&family=Trocchi&display=swap');

.Contact{
    overflow: hidden;
}


.contact-section{
    font-family: 'Noto Serif Devanagari', serif;
    font-size: clamp(0.7rem, 1vw, 1.5rem);
}
form{
    
    /* display: grid; */
    /* border: 3px solid hsl(95, 47%, 27%); */
    color: rgba(240,240,240,1);
    background: hsla(95, 25%, 20%,1);
    
    border-radius: 0.3rem;
    width: 75%;
    margin: 0 auto;
    box-shadow: 0 0 0.2rem 0.15rem #000;
    
}


.contacts-main-grid-container3{
    width: 95%;
    margin: 3rem auto;
    display:grid;
    
    grid-template-columns: 65% 35%;
    
}

input{
    /* font-size: 1.3rem; */
    margin-bottom: 0.5rem
    
}

textarea{
    /* font-size: 1.25rem; */
    height: 10rem;
    margin-bottom: 0.5rem
}

.contact-info-grid-container2{
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    justify-self: center;
}

.contact-info-grid-container2 h4{
    margin: 0.5rem;
    text-decoration: underline;
    border-bottom: 2px solid black;
    
}

.contact-info-grid-container2 p{
    margin: 0;
}
.form-inner-width, .form-inner-width2{
    width: 55%;
    
}

.form-inner-width{
    margin: 2rem auto
}
.form-inner-width2{
    display: grid;
    margin: 0 auto;
}
.form-inner-width h2{
    margin: 0.5rem auto
}
.form-inner-grid-container{
    display: grid;
}
@media(max-width: 750px){
    .contacts-main-grid-container3{
        
        grid-template-columns: 100%;
        justify-content: center;
        
    }
    form{
        width: 100%
    }
    
    textarea{
        
        height: 6.5rem;
    }
    .contact-info-grid-container2{
        
        grid-template-columns: 1fr ;
    }
    .form-inner-width{
        width: 70%;
    }
    
}

.contact-info-grid-container2 a{
    text-decoration: underline;
}

input[type="submit"] {
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 130%;
    width: 80% !important; 
    background-color: hsl(95, 35%, 27%);
    border: 3px solid hsl(73, 45%, 24%);
    border-radius: 4rem;
    
    cursor: pointer;
    margin: 2rem auto 2rem auto !important;
    display: block;
    padding: 1.2rem;
    
    transition: all 0.2s;
 }
 /* margin: '1rem auto', width: '50%', padding: '1rem' */

 input[type="submit"]:hover{
    border: 3px solid hsl(73, 45%, 29%);
    background-color: hsl(95, 35%, 24%);
}

input[type="submit"]:active{
    background-color: hsl(95, 35%, 30%);
    border: 3px solid hsl(73, 45%, 15%);
}

input[type="submit"]:focus{
    
    border: 3px solid hsl(73, 45%, 15%);
}


