.header-nav{
    position: fixed;
    width: 100%;
    z-index: 3000;
    transition: all 0.3s;

}
@import url('https://fonts.googleapis.com/css2?family=Cutive&family=Montserrat+Subrayada:wght@700&family=Noto+Serif+Devanagari:wght@500&family=Oswald:wght@500&family=Pacifico&family=Sriracha&family=Staatliches&family=Trocchi&display=swap');

.contactAndSocialMediaNav{
    height: 5.5vh;
    background: hsl(95, 47%, 27%);
    padding: 0 1rem;
    display: grid;
    grid-template-columns: 20% 80%;
    container-type: inline-size;
}

.socials{
    margin-left: 30%;
    width: 5vw;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0.2rem;
    
}

.socials img{
    border-radius: 50%;
}
.contact-info-grid-container{
    width: 65%;
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: clamp(0.6rem, 1.4vw, 3rem);
    
    justify-self: end;
    margin-right: 7%;
}

.contact-info-wrapper{
    width: 100%;
    display: grid;
    grid-template-columns: 25% 75%;
    align-items: center;
    
}
.info-wrapper1{
    justify-self: unset;
}
.contact-info{
    padding: 0;
    margin: 0;
}
.phone-icon{
    width: 1.5vh;
    justify-self: center;
    margin: 0 0.5rem;
}
.mail-icon{
    width: 3.5vh;
}
@container(min-width: 1200px){
    .mail-icon{
        justify-self: center;
    }  
}
@container(min-width: 1450px){
    .contact-info-grid-container{
        width: 50%;
        margin-right: 5%;
    }
}
@container(max-width: 600px){
    .mail-icon{
        width: 2rem;
    }
    .phone-icon{
        width: 0.8rem;
    }
    .socials img{
        
        width: 1.3rem;
    }
    .socials{
        margin-left: 5%;
    }
}
@container(max-width: 455px){
    .mail-icon{
        width: 1.6rem;
    }
    .phone-icon{
        width: 0.6rem;
    }
}
@container(max-width: 400px){
    .mail-icon{
        display: none;
    }
    .phone-icon{
        width: 0.6rem;
    }
    .contact-info-grid-container{
        width: 75%
    }
}
@media (max-width: 300px){
    .socials img{
        
        width: 1.3rem;
    }
    .socials{
        margin-left: 0

    }

    .contact-info-grid-container{
        width: 65%;
        display: grid;
        grid-template-columns: 50% 50%;
        font-size: clamp(0.5rem, 2vw, 3rem);
        
        justify-self: end;
        
    }
}


.separation-line{
    height: 1px;
    background-color: white;
}

.main-nav{

    width: 100%;
    background-color: hsla(0, 0%, 0%, 0.95);
    animation-name: appear;
    animation-duration: 1s;
    animation-direction: normal;
    transition: all 1s;
    overflow: hidden;
}

.main-nav-active{
    height: 100vh;
    height: 100dvh;
    height: 100svh;
    background-color: hsla(0, 0%, 0%, 0.8);
    width: 70%;
    overflow: scroll;
}

.idk{
    margin: 0;
    padding: 0;
    z-index: 15;
    color: white;
    display: grid;
    justify-content: end;
    text-align: center;
    list-style: none;
    grid-template-columns: 20% 30% repeat(5, 10%);
    
    text-decoration: none;
}
.idk a {
    display: grid;
    align-items: center;
    text-decoration: none;
}

.home-btn{ 
    
    height: 3rem
}

.burger-btn-line{
    margin: 0 auto;
    width: 1rem;
    height: 3px;
    background: rgb(220,220,220);
    margin-bottom: 3px;
    border-radius: 1rem;
    transition: width 1s, transform 1s, opacity 0.6s, background-color 1.5s ;
    
}
.burger-btn{
    margin: 0.5rem 2rem;
    width: 2rem;
    height: 2rem;
    display: grid;
    justify-content: center;
    align-items: center;
    background: none;
    border: 2px solid rgb(220,220,220);
    border-radius: 0.4rem;
    
    
   
}
.nav-active-burger-line1{   
    transform: translate(0,7.5px) rotate(45deg) ;
    width: 1.2rem
}

.nav-active-burger-line2{
    
    opacity: 0;
    
    
}
.nav-active-burger-line1, .nav-active-burger-line3{
    background: rgb(136, 42, 42);
}
.nav-active-burger-line3{
    width: 1.2rem;
    transform: translate(0, -4.5px) rotate(-45deg) ;
    
}

.active-nav-list{
    padding: 0;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    
    
    
}
.active-nav-list a {
    text-decoration: none;
}
.active-nav-list li{
    font-family: 'Oswald', sans-serif;
    font-size: 1.2rem;
    background: rgba(0,0,0,0.9);
    border-radius: 0;
    border-left: none;
    border-right: none;
    padding-left: 1.5rem;
    color: white;
    padding: 0.5rem;
    margin: 1rem 0;

}
.active-nav-list li:hover{
    background: hsl(95, 47%, 27%);
}


.active-nav-home-btn{
    width: 50%;
    border: 1px solid white !important;    
    margin: 1.5rem auto !important;
    padding: 0.9rem  0!important;
    text-align: center;
    border-radius: 0.15rem !important;

}


.social-media-icon{
    cursor: pointer;
    filter: contrast(130%) brightness(90%);

}

.social-media-icon:hover{
    filter: brightness(125%);
}

.instagram-icon{
    width: 3.6vh;
}
.facebook-icon{
    width: 4.1vh;
}



.idk li {
    font-family: 'Oswald', sans-serif;
    font-size: clamp(1.1rem, 1.5vw, 5rem);
    height: 100%;

    transition: all 0.35s;
    
    color: rgb(230, 230, 230);
    text-decoration: none;
    display: grid;
    align-items: center;
    
    /* background: rgba(0,0,0,0.9); */
    
}
.active li{
    background: hsl(95, 47%, 27%);
    /* box-shadow: #000  0rem 0rem 0.4rem 0.1rem ; */
    color: rgb(12, 12, 12);
}
.idk li:hover{
    cursor: pointer;
    background: hsl(95, 47%, 27%);
    /* box-shadow: #000  0rem 0rem 0.4rem 0.1rem ; */
    color: rgb(12, 12, 12);
    
    

}


/* .burger-btn-inactive{

} */


.navTitle{

    align-items: center;
    font-family: 'Pacifico', cursive;
    
    margin: 0;
    
    color: hsl(0, 83%, 32%);
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black; 
    font-size: clamp(1rem, 1.5vw, 4rem)
}

@media(min-width: 700px){
    .idk{
        height: 100%;
    }
    .main-nav{
        height: 6.8vh;
    }
}

.scrollingDown{
    transform: translateY(-200px);
}

