/* AboutUs css */
.AboutUs{
    overflow: hidden;
}
.AboutUs h1 {
    font-size: 200%;
}


/* AboutUsSection css */
.AboutUsSection{
    display: grid;
    align-items: center;
    width: 100%;
    background-size: cover !important;
    background-repeat: no-repeat;
    padding: 0.5rem 0
}
.customHrDiv{
    width: 80%;
    height: 4px;
    background: rgb(15,15,15);
    margin: 2rem auto;
    box-shadow: 0 0 0.2rem 0.01rem rgb(240,240,240);
}

.AboutUsSection ul{
    color: rgb(240,240,240)
    /* the marker is on the component itself as an inline style property */
}

.AboutUsSection li::marker{
    font-size: 3rem;  
}

.AboutUsSection li{
    font-size: 1.2rem;
}

.about-us-outer-wrapper{
    width: 70%;
    background: rgba(0,0,0,0.9);
    padding: 1rem;
    border-radius: 0.5rem;
    margin: 0 auto;
    /* The transition property below is for the planned case of multiple about us section cards, which would appear as the page is scrolled.
    The former plan however was scrapped, and only one card was left, I therefore decided to keep it static for now
    The changes which would occur upon the element being reached by scrolling is on the component itself - look at the element with the 
    given class, where such a configuration of it would be commented below the current configuration 
    
    LASTLY - the configuration of appearance is old and it appears on a FIXED scroll value, therefore, it needs to be fixed
    to be when the element is reached, such as in the SERVICES -> GARDENING SERVICES section
    */
    /* transition: all 1.5s; */

}

@media(max-width: 700px){
    .about-us-outer-wrapper{
        width: 85%;
    }
}

@media(min-width: 2500px){
    .AboutUsSection li::marker{
        font-size: 3vw;  
    }
    .AboutUsSection li{
        font-size: 1.2vw;
    }
    .AboutUs h1{
        font-size: 1.8vw;
    }
    .about-us-outer-wrapper{
        width: 80%;
 
        padding: 2vw;

    }
}

