.Home{
    overflow: hidden;
}
.home-section-outermost-container{
    position: relative;
    padding: 1.5rem;
    
}

.home-title-container{
    font-size: clamp(0.7rem, 1.5vw, 5rem);
    width: 100%;
    font-family: 'Trocchi', serif;
}
.home-title-container h1,
.home-title-container h2,
.home-title-container h3{
    text-align: center;
    margin: 0
}

.home-section-sub-title-container{
    font-size: clamp(0.7rem, 1.5vw, 5rem);
    width: 100%;
    text-align: center;
    font-family: 'Trocchi', serif;
}

.longest-hr{
    margin: 1rem auto 0.3rem auto;
    width: 80%;
}

.mid-length-hr{
    margin: 0 auto 0.3rem auto;
    width: 50%;
}
.mid-length-hr-title{
    margin: 0 auto 3rem auto;
    width: 50%;
}

.shortest-hr{
    margin: 0 auto 3rem auto;
    width: 25%;
}
a {
    text-decoration: none;
}
.mask111{
    position: absolute;
   
    
    inset: -2rem 0;
    background: black;
    -webkit-mask: unset;
    mask: unset;
    -webkit-mask-position: unset ;
    -webkit-mask-size: unset;
    
    -webkit-mask-repeat: no-repeat;
}

.intro-cards-grid-container{
    height: 45vh;
    display: grid;
    grid-template-columns: repeat(3, 30%);
    justify-content: center;
    margin: 0 auto;
    gap: 1rem;

    font-family: 'Noto Serif Devanagari', serif;
    

    container-type: inline-size;
}

@media (max-width: 700px) {
    .intro-cards-grid-container{
        display: grid;
        grid-template-columns: 100% ;
        height: 65vh;
    }
    .main-nav{
        height: 3rem;
    }
    
}

.lolz{
    background-size: cover !important;
    filter: unset;
    transition: all 0.5s;
    color:rgb(180, 180, 180);
    border-style: ridge;
    border-color: rgba(200, 200, 200, 1); 
    /* hsl(95, 47%, 27%) */
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0.3rem;
    container-type: inline-size;
}
.lolz .intro-cards-paragraph-wrapper {
    display: grid;
    height: 100%;
    grid-template-rows: 70% 30%;
    position: absolute;
    
    bottom: -30%;
    left: 0;
    right: 0;
    margin: 0;
    

    background-color: rgba(10, 10, 10, 0.95);
    transition: all 0.5s
}

/* box-shadow: inset 2rem 2rem 2rem  rgba(10, 10, 10, 0.7), inset -2rem -2rem 2rem  rgba(10, 10, 10, 0.7); */
.lolz:hover{
    filter: contrast(120%) brightness(1.15);
    box-shadow: inset 0rem 2rem 2rem  rgba(10, 10, 10, 0.7)
    
}

.lolz:hover .intro-cards-paragraph-wrapper {
    background-color: rgba(180, 180, 180, 0.95);
    color:rgb(22, 22, 22);
    bottom: 0%;
}

.lolz a{
    margin: 0 auto 2.5% auto;
}
.lolz p {
    align-self: center;
    text-align: center;
    margin: 2.5%;
    font-size: clamp(0.7rem, 1.3vw, 4rem); 
}




.why-choose-us-grid-container{
    width: 90%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    justify-content: center;
    gap: 15px;
    font-size: clamp(0.8rem, 1.5vw, 1rem); 
}




.lolz2{
    opacity: 0;
    transform: translateX(-100%);

    transition: opacity 1.1s, transform 0.5s, box-shadow 1.2s, filter 1.2s;
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    text-align: center ;
    height: 8rem;
    padding: 15%;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 1.5rem;
    border: hsl(95, 47%, 27%) 1px solid;
    box-shadow: inset 0rem 0rem 0.8rem 0.4rem hsl(95, 47%, 27%);
    color: rgb(180, 180, 180);
    font-family: 'Noto Serif Devanagari', serif;
    font-size: clamp(0.75rem, 1vw, 2rem);
    filter:  drop-shadow(0 0 2rem  hsl(95, 47%, 27%));
    
}

.lolz2:nth-child(2){
    transition-delay: 100ms;
}
.lolz2:nth-child(3){
    transition-delay: 180ms;
}
.lolz2:nth-child(4){
    transition-delay: 260ms;
}

.lolz2-image-no-filter{
    width: 20%;
}

.lolz2-image-with-filter{
    width: 20%;
    filter: invert(80%)
}

.intro-gallery-grid-container{
    width: 95%;
    
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    margin: 0 auto;
    gap: 5vh;
    transition: all 1s;
    text-align: center;
}

.intro-gallery-grid-item img{
    position: relative;
    
    color: white;
    text-align: center;
    border-radius: 0.15rem;
    padding: 0;
    overflow: hidden;
    box-shadow: 0 0 0.15rem 0.05rem #000;
    font-size: clamp(0.75rem, 1.8vw, 1.1rem); 
    
    display: grid;
}
.intro-gallery-grid-item p{
    position: absolute;
    bottom: -45%;
    background: rgba(0, 0, 0, 0.9);
    width: 100%;
    transition: all 0.55s ;
    
    
}
.picture-and-description-inner-grid-container{
    display: grid;
    grid-template-columns: 47.5% 47.5%;
    justify-content: space-between;
    opacity: 0;
    transition: all 1.5s
}


@media (max-width: 700px){
    .why-choose-us-grid-container{
        width: 100%;
        grid-template-columns: repeat(2, 55%);
        gap: 10px;
    }
    .intro-gallery-grid-container{
        width: 100%;
           
    }
    .picture-and-description-inner-grid-container{
        grid-template-columns: 100%;
    }
    
}
@media (max-width: 750px){
    .intro-gallery-grid-container{
        grid-template-columns: 100%;
    }
}
.intro-gallery-photo{
    margin: 0;
    width: 100%;
    position: unset
    
}


.intro-gallery-grid-item{
    transition: all 0.5s;
    
    filter: contrast(105%);
}

.intro-gallery-grid-item:hover{
    filter: contrast(140%);
    
}
.intro-gallery-grid-item:hover p{
    bottom: 0
    
}
.intro-gallery-grid-item:hover .intro-gallery-photo{
    
    
}

.pisofshit{
    position: absolute; 
    left: 0; 
    right: 0; 
    top: 0;
    margin-left: auto; 
    margin-right: auto; 
    width: 100%; /* Need a specific value to work */
    height: 100%;
}
@import url('https://fonts.googleapis.com/css2?family=Cutive&family=Montserrat+Subrayada:wght@700&family=Noto+Serif+Devanagari:wght@500&family=Oswald:wght@500&family=PT+Sans+Narrow&family=Pacifico&family=Sriracha&family=Staatliches&family=Trocchi&display=swap');
.lolBtn{
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: clamp(0.9rem, 1.4vw, 5rem);
    background-color: hsl(95, 35%, 27%);
    border: 2px solid hsl(73, 45%, 20%);
    border-radius: 2rem;
    
    padding: 1vw;
    cursor: pointer;
    transition: all 0.2s;

}

.lolBtn:hover{
    border: 2px solid hsl(73, 45%,32%);
    background-color: hsl(95, 35%, 24%);
}

.lolBtn:active{
    background-color: hsl(95, 35%, 30%);
    border: 2px solid hsl(73, 45%, 15%);
    
}
.lolBtn:focus{
    
    border: 2px solid hsl(73, 45%, 15%);
}


.see-more-gallery-redirect-btn{
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: clamp(0.9rem, 1.7vw, 5rem);
    background-color: hsl(95, 35%, 27%);
    border: 3px solid hsl(73, 45%, 24%);
    border-radius: 3vw;
    
    cursor: pointer;
    margin: 2rem auto 0 auto;
    display: block;
    padding: 1.2vw;
    
    transition: all 0.2s;
}

.see-more-gallery-redirect-btn:hover{
    border: 3px solid hsl(73, 45%, 29%);
    background-color: hsl(95, 35%, 24%);
}

.see-more-gallery-redirect-btn:active{
    background-color: hsl(95, 35%, 30%);
    border: 3px solid hsl(73, 45%, 15%);
}

.see-more-gallery-redirect-btn:focus{
    
    border: 3px solid hsl(73, 45%, 15%);
}


.intro-cards-paragraph-wrapper{

}





.see-more-div{
    font-family: 'Noto Serif Devanagari', serif;
    text-align: center;
    background: rgba(200, 200, 200, 0.2)
}
.see-more-div h3{
    margin: 0
}
.see-more-div a{
    text-decoration: underline;
}
.picture-and-description-inner-grid-container h3{
    font-family: 'Noto Serif Devanagari', serif;
    font-size: clamp(0.8rem, 3vw, 5rem);
    align-self: center;
}



.elementAppear{
    opacity: 1;
    transform: translateX(0%);
    
    filter: drop-shadow(0rem 0rem 0rem )
}