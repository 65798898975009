@import url('https://fonts.googleapis.com/css2?family=Cutive&family=Farsan&family=Montserrat+Subrayada:wght@700&family=Noto+Serif+Devanagari:wght@500&family=Oswald:wght@500&family=PT+Sans+Narrow&family=Pacifico&family=Sriracha&family=Staatliches&family=Trocchi&display=swap');

.SectionSplitter{
    font-size: clamp(0.8rem, 2.8vh, 10rem);
    background: rgba(0,0,0,0.9);
    display: grid;
    align-items: center;
    width: 100%;
    height: 10vh;
}

.SectionSplitter h2{
    margin: 0;
    text-align: center;
    color: rgb(240, 240, 240);
    letter-spacing: 0.1rem;
    word-spacing: 0.5rem 0;
    font-family: 'Staatliches', cursive;
}

