.Footer{
    background: rgba(0,0,0, 0.9);
    overflow: hidden;
    
}

.Footer p {
    margin: 0;
    font-size: clamp(0.7rem, 1vw, 4rem);
}


.Footer li{
    
    font-size: clamp(0.7rem, 1vw, 4rem);
    
}
.footer-top-grid-container{
    
    padding-top: 1rem;
    color: rgb(201, 201, 201);
    display: grid;
    grid-template-rows: 1fr 2fr;
    
    
    margin: 0 auto;
    
    
}
.footer-top-grid-container a{
    color: rgb(201, 201, 201);
    transition: all 0.15s;
    align-self: center;
}
.footer-top-grid-container a:hover{
    color: hsl(95, 47%, 27%);
}

.footer-copyright-text-box{
    color: rgba(192, 192, 192, 0.9);
    padding: 0.5rem 0;
    background: rgba(0,0,0,0.3);
    width: 100%;
    display: grid;
    text-align: center;
    align-items: center;
    
}

.footer-sections-grid-container{
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(5, 10%);
    justify-items: center;
    justify-content: center;
    
    
}
.socials-grid{
    
    grid-template-columns: repeat(4, 5%);
    
}
/* .footer-top-grid-container .footer-sections-grid-container:first-of-type{
    grid-template-columns: repeat(4, 25%) !important;
   width:80%;
   
   
   align-items: center;
   margin: 0 auto;
}
*/
.footer-sections-grid-container img{
    border-radius: 50%;
} 

.footer-social-media-icon{
    width: 2rem;
    cursor: pointer;
    filter: contrast(130%) brightness(90%) grayscale(70%);

}

.footer-social-media-icon:hover{
    filter: brightness(125%);
}

@media(max-width: 700px){
    
    .socials-grid{
        
        grid-template-columns: repeat(4, 20%);
        
    }
    .footer-top-grid-container ul{
        
        grid-template-columns: repeat(5, 20%) 
        
        
        
    }
}

.footer-top-grid-container ul{
    padding: 0;
    list-style: none;
}