@import url('https://fonts.googleapis.com/css2?family=Cutive&family=Farsan&family=Montserrat+Subrayada:wght@700&family=Noto+Serif+Devanagari:wght@500&family=Oswald:wght@500&family=PT+Sans+Narrow&family=Pacifico&family=Sriracha&family=Staatliches&family=Trocchi&display=swap');

.SectionTitle{
    margin-top: 2rem;
    font-family: 'Trocchi', serif;
}

.SectionTitle hr:nth-child(1){
    margin: 0.2rem auto 0.2rem auto;
    width: 50%;
}

.SectionTitle hr:nth-child(2){
    margin: 0.2rem auto;
    width: 70%
}

.SectionTitle h2{
    margin: 0 auto;
    text-align: center;
    background: rgba(0,0,0,0.1);
    width: 70%;
    padding: 0.25rem 0;
    font-size: clamp(1rem, 2vw, 2vw);
}


.SectionTitle hr:nth-child(4){
    margin: 0.2rem auto 0.2rem auto;
    width: 70%;
}

.SectionTitle hr:nth-child(5){
    margin: 0.2rem auto 0.2rem auto;
    width: 50%;
}

