.services-text{
    
    font-family: 'Noto Serif Devanagari', serif;
    line-height: 2.1rem;
    font-size: clamp(0.75rem, 1.2vw, 2rem) !important
    
}

/* .ServicesPageSection:last-of-type{
    border: none
} */


.services-grid-item2{
    display: grid;

}

.services-grid-item2 img{
    align-self: center;
}